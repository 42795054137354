/*----header-----*/
header {
	color: var(--color_0);
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9998;
	transition: 0.6s;
	&.sticky {
		.baner-head {
			transition: 0.6s;
		}
		.search-panel.active {
			top: 100%;
		}
		.header-bottom {
			margin-top: -3rem;
			transition: 0.6s;
		}
	}
	.navigation {
		@media (max-width: 992px) {
			display: none;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 4.375rem;
		position: relative;
	}

	.header-top {
		background: var(--color_1);
		box-shadow: 0 0.375rem 0.5rem 0 rgba(0, 0, 0, 0.4);
		position: relative;
		z-index: 99;
		height: 4.375rem;
	}

	.header-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--color_1);
		height: 3rem;
		transition: 0.6s;
		position: relative;
		z-index: 10;
		@media (max-width: 992px) {
			display: none;
		}
		ul {
			display: flex;
			align-items: center;
			gap: 1.5rem;
			list-style-type: none;
			padding: 0;
			li {
				position: relative;
				font-family: "Inter-Regular";
				font-size: 1rem;
				font-weight: 700;
				ul {
					display: none;
				}
				a {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					sup {
						color: #f8ea14;
						font-size: 0.5rem;
						font-weight: 700;
						text-transform: uppercase;
						top: -0.5rem;
						position: relative;
					}
				}
			}
			.border {
				width: 1px;
				height: 2.25rem;
				opacity: 0.5;
				background: var(--color_0);
			}
		}
	}

	.lang-search {
		width: 12.5rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
		font-size: 0.75rem;
		font-weight: 700;
		@media (max-width: 992px) {
			width: auto;
		}
	}
	.drop-head {
		@media (max-width: 992px) {
			display: none;
		}
	}
}
.drop-head {
	@media (max-width: 992px) {
		//position: relative;
	}
	.button-lang {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 0.5rem;
		border-radius: 0.125rem;
		border: 1px solid var(--border-search, var(--color_8));
		cursor: pointer;
		text-transform: uppercase;
		img,
		svg {
			width: 1.125rem;
			height: auto;
		}
		&:hover {
			background: var(--color_1);
		}
	}
}
.params-form {
	position: absolute;
	top: 100%;
	right: -100%;
	//margin-top: 1rem;
	width: 18.75rem;
	padding: 3rem 1.5rem 1.5rem;
	background: var(--color_0);
	box-shadow: 0 0.375rem 0.5rem 0 rgba(0, 0, 0, 0.4);
	transition: 0.8s;
	@media (max-width: 992px) {
		position: fixed;
		top: 4.375rem;
		right: auto;
		left: -20rem;
		z-index: 99999;
		width: 18rem;
	}
	&.active {
		right: -2rem;
		transition: 0.8s;
		@media (max-width: 992px) {
			left: 1rem;
		}
	}
	.button {
		width: 100%;
		height: 2.875rem;
		font-size: 0.875rem;
	}
	.close {
		width: 1.375rem;
		height: 1.375rem;
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before,
		&:after {
			content: "";
			display: block;
			width: 1.375rem;
			height: 2px;
			background: var(--color_5);
			position: absolute;
			transition: 0.6s;
			top: 0.3rem;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
		&:hover {
			&:before,
			&:after {
				content: "";
				background: var(--color_1);
			}
		}
	}
}
.logo {
	display: flex;
	align-items: center;
	gap: 1rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1;
	color: var(--color_10);
	&:hover {
		color: var(--color_10);
	}
	img,
	svg {
		height: 2.25rem;
		width: auto;
	}
	span {
		color: var(--color_0);
	}
}

.toggle-search {
	width: 1.375rem;
	height: 1.375rem;
	cursor: pointer;
	background-image: url(../img/icons/search-white.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	transition: 0.6s;
	@media (max-width: 992px) {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
	}
	&.active {
		background-image: url(../img/icons/search-close.svg);
		transition: 0.6s;
	}
}

.toggle-nav {
	width: 1.375rem;
	height: 1.375rem;
	cursor: pointer;
	background-image: url(../img/icons/burger.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	transition: 0.6s;
	display: none;
	@media (max-width: 992px) {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
	}
	&.active {
		background-image: url(../img/icons/search-close.svg);
		transition: 0.6s;
	}
}

.search-panel {
	height: 4rem;
	position: absolute;
	top: -4rem;
	left: 0;
	right: 0;

	background: var(--color_6);
	padding: 0.5rem 0;
	transition: 0.6s;
	z-index: 15;
	&.active {
		top: 100%;
		transition: 0.6s;
		@media (max-width: 992px) {
			top: 100%;
		}
	}
	.searchs {
		position: relative;
		input {
			width: 100%;
			height: 3rem;
			box-sizing: border-box;
			border-radius: 0.1875rem;
			padding: 0.25rem 1rem;
			border: 1px solid var(--border-search, var(--color_8));
			background: var(--select-menu, #2e2f31);
			&:focus {
				border-color: var(--color_1);
			}
		}
	}
	.ajax-search {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background: var(--color_0);
		color: #2e2f31;
		box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
		max-height: calc(100vh - 7.5rem);
		overflow-y: auto;
		.result {
			padding: 1.5rem;
			display: flex;
			flex-wrap: wrap;
			gap: 0.75rem 1.5rem;
			border-bottom: 1px solid #f1f1f1;
			&:last-child {
				border: none;
			}
			.title {
				width: 100%;
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: 700;
				color: var(--color_1);
				display: flex;
				align-items: center;
				gap: 0.5rem;
				img,
				svg {
					width: 1.375rem;
					height: auto;
					path {
						fill: var(--color_1);
						//stroke: var(--color_1);
					}
				}
			}
			.item {
				.title {
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					color: var(--color_5);
				}
			}
			.item-casino {
				width: calc(25% - 1.5rem);
				@media (max-width: 992px) {
					width: calc(50% - 1.5rem);
				}
				@media (max-width: 680px) {
					width: 100%;
				}
			}
			.item-provider {
				width: calc(25% - 1.5rem);
				@media (max-width: 992px) {
					width: calc(50% - 1.5rem);
				}
				@media (max-width: 680px) {
					width: 100%;
				}
				.post-casino-box {
					display: flex;
					cursor: pointer;
					align-items: center;
				}
			}
			.item-guides {
				width: calc(33% - 1.5rem);
				@media (max-width: 992px) {
					width: calc(50% - 1.5rem);
				}
				@media (max-width: 680px) {
					width: 100%;
				}
				.post-guides {
					align-items: center;
					.title {
						text-transform: none;
					}
				}
				.post-popular {
					display: grid;
					grid-template: 1fr / 1fr;
					column-gap: 0.5rem;
					padding: 1rem 0;
					border-top: 1px solid #f1f1f1;
					border-bottom: none;
					.image {
						display: none;
					}
					.subtitle-box {
						display: flex;
						gap: 1.25rem;
						align-items: center;
						margin-top: 0.375rem;
					}
				}
			}
			.desc {
				color: #c3c3c3;
			}
		}
	}
}
