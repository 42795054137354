.navigation {
	display: flex;
	align-items: center;

	> ul {
		display: flex;
		gap: 2rem;
		list-style-type: none;
		padding: 0;
		// margin-left: -1.25rem;
		li.country-hidden {
			display: none !important;
		}
		> li {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 0.75rem;
			font-size: 1.1rem;
			font-weight: 700;
			padding: 1.25rem 0;
			//position:relative;
			&.menu-item-has-children {
				&:after {
					content: "";
					min-width: 0.5rem;
					width: 0.5rem;
					height:  0.5rem;
					display: block;
					border-bottom: 2px solid var(--color_0);
					border-right: 2px solid var(--color_0);
					transform: rotate(45deg);
					margin-top: -2px;
				}
				&:hover{
					&:after{
						transform: rotate(-135deg);
						margin-top: 0;
					}
				}
			}
			> a {
				display: flex;
				align-items: center;
				padding: 0.375rem 0;
				box-sizing: border-box;
				border-bottom: 0.125rem solid transparent;
				svg {
					margin-right: 0.5rem;
					path {
						fill: #686a6b;
					}
				}
				&:hover {
					border-bottom: 0.125rem solid var(--color_2);
					svg {
						path {
							fill: var(--color_2);
						}
					}
				}
			}
			.sub-menu {
				//width: 25rem;
				position: absolute;
				top: 100%;
				right: 0;
				left: 0;
				margin: 0 auto;
				//transform: translate(50%, 0);
				list-style: none;
				padding:1.5rem 2.5rem 2.5rem;
				background: var(--color_1);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 1rem;
				display: none;
				.menu-groop {
					width: calc(25% - 1rem);
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
				}
				li {
					width: 100%;
					> a {
						//text-transform: uppercase;
						font-family: "Inter-Regular";
						font-size: 1rem;
						transition: 0.3s;
						&:hover {
							color: var(--color_10);
						}
					}
					ul {
						display: block;
						list-style: none;
						padding: 0;
						margin: 0.625rem 0;
						li {
							display: flex;
							justify-content: space-between;
							align-items: center;
							gap: 0.5rem;

							&:after {
								content: "";
								min-width: 0.5rem;
								width: 0.5rem;
								height: 0.5rem;
								border-top: 1px solid transparent;
								border-right: 1px solid transparent;
								transform: rotate(45deg);
								margin-right: 1rem;
							}
							&:hover {
								&:after {
									border-top: 1px solid var(--color_3);
									border-right: 1px solid var(--color_3);
								}
							}
							width: 100%;
							margin-bottom: 0.625rem;
							a {
								text-transform: none;
								font-weight: normal;
								color: var(--color_9);
								&:hover {
									color: var(--color_3);
								}
							}
						}
					}
				}
			}
			&:hover {
				.sub-menu {
					display: flex;
				}
			}
		}
	}
}

.nav-guide {
	background: var(--Color, var(--color_0));
	box-shadow: 0 0.25rem 0.5rem 0 rgba(29, 39, 48, 0.16);
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		> li {
			padding: 1rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #e3e7ef;
			&:last-child {
				border: none;
			}
			&.menu-item-has-children {
				a {
					width: calc(100% - 1.5rem);
				}
				.togg {
					width: 0.875rem;
					height: 0.875rem;
					background-image: url(../img/icons/arrow-right.svg);
					background-size: contain;
					transform: rotate(90deg);
					opacity: 0.6;
					cursor: pointer;
					@media (max-width: 992px) {
						width: 1.25rem;
						height: 1.25rem;
					}

					&:hover {
						opacity: 1;
					}
					&.active {
						transform: rotate(-90deg);
					}
				}
			}
			&.current-menu-item {
				a {
					color: var(--color_2);
				}
			}
			a {
				color: var(--color_5);
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				&:hover {
					color: var(--color_2);
				}
			}
			.sub-menu {
				width: 100%;
				margin-top: 0.5rem;
				display: none;
				&.active {
					display: block;
				}
				li {
					padding: 0.5rem;
					border: none;
					&:last-child {
						padding-bottom: 0;
					}
					a {
						color: var(--color_8);
						&:hover {
							color: var(--color_2);
						}
					}
				}
			}
		}
	}
}

.nav-blog {
	padding: 1.25rem 0 1.5rem;
	margin-top: 4rem;
	margin-bottom: -0.5rem;
	background: #f0f0f0;
	position: relative;
	z-index: 10;
	@media (max-width: 992px) {
		margin-top: 1.5rem;
	}
	@media (max-width: 414px) {
		margin-top: 1.1rem;
	}
	ul {
		margin: 0;
		padding: 0 1.25rem;
		list-style: none;
		display: flex;
		gap: 1rem;
		border-bottom: 1px solid var(--color_5);
		@media (max-width: 992px) {
			overflow-x: auto;
			white-space: nowrap;
		}
		li {
			border-bottom: 0.25rem solid transparent;
			&.active {
				border-bottom-color: var(--color_2);
				@media (max-width: 992px) {
					border-bottom-color: transparent;

					a {
						color: var(--color_2);
					}
				}
			}
			a {
				font-size: 1.25rem;
				font-style: normal;
				font-weight: 700;
				line-height: 1.5rem; /* 120% */
				text-transform: uppercase;
				color: var(--color_5);
				display: flex;
				padding: 0.25rem 0;

				@media (max-width: 992px) {
					font-size: 0.9rem;
				}
				&:hover {
					color: var(--color_2);
				}
			}
		}
	}
}

.blog-page {
	padding-top: 18.75rem;
}

.nav-mobile {
	position: fixed;
	top: 0;
	bottom: 0;
	left: -100%;
	width: 20rem;
	background: var(--color_6);
	color: var(--color_0);
	box-shadow: 0 0.375rem 0.5rem 0 rgba(0, 0, 0, 0.4);
	z-index: 9999;
	overflow: hidden;
	display: none;
	transition: 0.6s;
	@media (max-width: 992px) {
		display: block;
		&.active {
			left: 0;
			transition: 0.6s;
		}
	}
	.head {
		height: 4.375rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		border-bottom: 1px solid #2e2f31;
		.close {
			width: 1.375rem;
			height: 1.375rem;
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			&:before,
			&:after {
				content: "";
				display: block;
				width: 1.375rem;
				height: 2px;
				background: var(--color_0);
				position: absolute;
				transition: 0.6s;
				top: 0.3rem;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
	}
	.navs {
		max-height: calc(100% - 4.375rem);
		overflow-y: auto;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 22px 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.togg,
			.togg-in {
				min-width: 1.5rem;
				width: 1.5rem;
				height: 1.5rem;
				background-image: url(../img/icons/arrow-white.svg);
				background-size: contain;
				cursor: pointer;
			}
			a {
				display: flex;
				align-items: center;
				box-sizing: border-box;
				font-size: 1.2rem;
				svg,
				img {
					width: 20px;
					height: auto;
					margin-right: 0.5rem;
					path {
						fill: #686a6b;
					}
				}
			}
			.sub-menu {
				position: absolute;
				top: 4.375rem;
				bottom: 0;
				left: 100%;
				width: 20rem;
				background: var(--color_6);
				color: var(--color_0);
				overflow-y: auto;
				z-index: 999;
				&.active {
					left: 0;
				}
				li {
					flex-wrap: wrap;
					padding: 20px 16px;
					.togg {
						&.active {
							transform: rotate(-180deg);
						}
					}
					&.sub-head {
						background: #2e2f31;
						padding: 20px 16px;

						.togg-in {
							transform: rotate(180deg);
						}
					}
					.sub-sub-menu {
						width: 100%;
						border-top: 1px solid var(--color_9);
						margin: 12px 0 0;
						display: none;
						&.active {
							display: block;
						}
						li {
							padding: 16px 0;
							font-size: 14px;
							color: var(--color_9);
						}
					}
				}
			}
		}
	}
	.head-nav-2 {
		background: var(--color_1);
		padding: 8px;
		li {
			padding: 16px 8px;
		}
		li.menu-item-665 {
			border-top: 1px solid var(--color_0);
			a {
				sup {
					color: #f8ea14;
				}
				svg {
					path {
						fill: #f8ea14;
					}
				}
			}
		}
	}
}
