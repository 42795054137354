/*-----generals-----*/
html {
	font-size: 16px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: "Inter-Regular";
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	@media (max-width: 1340px) {
		font-size: 15px;
	}
	@media (max-width: 1200px) {
		font-size: 14px;
	}
	@media (max-width: 992px) {
		font-size: 15px;
	}
}
body {
	font-family: "Inter-Regular";
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4;
	background-color: #fff;
	background-image: url(../img/bg.webp);
	color: var(--color_5);
	margin: 0;
	position: relative;
	&.no-scroll {
		position: fixed;
	}
}

html,
body {
	@media (max-width: 992px) {
		overflow-x: hidden;
	}
}

main {
	width: 100%;
	//padding-top: 4.375rem;
	@media (max-width: 992px) {
		//padding-top: 3.5rem;
	}
}
section {
	margin: 0 auto;
}

@media (min-width: 320px) {
	/* Работает в Firefox */
	* {
		scrollbar-width: thin;
		scrollbar-color: var(--color_1) transparent;
	}

	/* Работает в Chrome, Edge и Safari */
	*::-webkit-scrollbar {
		width: 0.3125rem;
		height: 0.2125rem;
	}

	*::-webkit-scrollbar-track {
		background: rgba(232, 238, 255, 0.3);
	}

	*::-webkit-scrollbar-thumb {
		background-color: var(--color_1);
		box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.05);
		border-radius: 0.125rem;
	}
	*::-webkit-scrollbar-thumb:hover {
		background-color: var(--color_2);
		cursor: pointer;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
}
.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 1rem;
	padding-right: 1rem;
}
.md-hidden {
	display: none;
}
.right {
	text-align: right;
}
.center {
	text-align: center;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	transition: 0.3s;
}
*:hover {
	transition: 0.3s;
}
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}
audio:not([controls]) {
	display: none;
	height: 0;
}

// link
a {
	background-color: transparent;
}
a:active,
a:hover {
	outline: 0;
	color: inherit;
}
a {
	color: inherit;
	text-decoration: none;
}
a:hover,
a:focus {
	color: inherit;
}
a:focus {
	outline: none;
	outline-offset: 0;
}

// img
figure {
	margin: 0;
}
img {
	border: 0;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}
svg,
svg:hover,
path,
polygon {
	transition: 0.05s;
}
svg:not(:root) {
	overflow: hidden;
}

//text
h1,
h2,
h3 {
	font-weight: normal;
	margin: 0;
}
// p {
// 	// color: $font-color-light;
// }
b,
strong {
	font-weight: bold;
}
dfn {
	font-style: italic;
}

small {
	font-size: 80%;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
mark {
	background: #ff0;
	color: var(--color_4);
}
hr {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	margin-top: 0;
	margin-bottom: 0;
	border: 0;
	border-top: 1px solid #f2f2f2;
}
pre {
	overflow: auto;
}
code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

//form
button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
}
input:focus,
button:focus,
select:focus,
textarea:focus {
	outline: none;
	outline-offset: 0;
}
button {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled],
html input[disabled] {
	cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
input {
	line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
input[type="search"] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 0.125rem;
	padding: 0.35em 0.625em 0.75em;
}
legend {
	border: 0;
	padding: 0;
}
textarea {
	overflow: auto;
}
optgroup {
	font-weight: bold;
}

//table
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,
th {
	padding: 0;
}

@media (min-width: 280px) {
	.container {
		width: 100%;
	}
}
@media (min-width: 1190px) {
	.container {
		width: 77.5rem;
	}
}
