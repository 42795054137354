.section-slider-casino {

	.section-title{
		width: 100%;
	}
	.casino-carousel{
		width: 100%;
		position: relative;
		margin-top: 1.5rem;
		.post-casino{
			width: 100%;
		}

		.swiper-pagination{
			bottom: -2rem;
		}
	}
}