.casino-grid.grid-view {
	width: 100%;
	padding: 0;
	justify-content: center;
	gap: 0.5rem 0.75rem;
	.post-casino {
		width: calc(18% - 1rem);
		border-radius: 0.1875rem;
		padding: 0.5rem;
		background: var(--color_3);
		text-align: center;
		position: relative;
		@media (max-width: 1140px) {
			width: calc(20% - 0.65rem);
		}
		@media (max-width: 992px) {
			width: calc(28% - 0.8rem);
		}
		@media (max-width: 540px) {
			width: calc(33.33% - 0.8rem);
		}
		@media (max-width: 480px) {
			width: calc(50% - 0.4rem);
		}
		.groop {
			background: var(--color_0);
			border-radius: 0.1875rem;
			padding: 0.625rem;
		}
		.num {
			font-family: "Inter-Bold";
			font-size: 1.5rem;
			margin-bottom: 0.625rem;
		}
		.image {
			display: block;
			width: 4rem;
			height: 4rem;
			margin: 0 auto 0.625rem;
			border-radius: 0.5rem;
			background-color: rgba(255, 255, 255, 0);
			background-size: cover;
			background-position: 50% 50%;
			box-shadow: 0 1rem 1.875rem 0 rgba(0, 0, 0, 0.08);
		}

		.title {
			color: var(--color_12);
			font-size: 0.875rem;
			line-height: 1.2;
			font-weight: 400;
			text-transform: uppercase;
		}
		.desc {
			color: var(--color_5);
			font-size: 0.875rem;
			line-height: 1.2;
			font-weight: 400;
			margin: 0.5rem 0;
			min-height: 2rem;
		}
		.button-gradient {
			svg,
			img {
				width: 0.5rem;
				margin-top: -0.125rem;
			}
		}
		.casino-rating {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;
			font-size: 0.875rem;
			line-height: 1;
			color: var(--color_0);
			padding-top: 0.625rem;
			margin-top: 0.625rem;
			border-top: 1px solid var(--color_0);
			span {
				font-family: "Inter-Bold";
				font-size: 0.875rem;
				line-height: 1;
			}
			&:before {
				content: "";
				display: block;
				width: 1.125rem;
				height: 1.125rem;

				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-image: url(../img/icons/star.svg);
			}
		}
	}
}

.top-home {
	.post-casino-1 {
		order: 3;
		.num {
			color: #f8c563;
		}
		&:before {
			content: "";
			width: 4.25rem;
			height: 4.25rem;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-image: url(../img/top-1.png);
			position: absolute;
			top: -2.5rem;
			left: -1.5rem;
			z-index: 20;
		}
	}
	.post-casino-2 {
		order: 2;
		.num {
			color: #a3a3a5;
		}
		&:before {
			content: "";
			width: 4.25rem;
			height: 4.25rem;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-image: url(../img/top-2.png);
			position: absolute;
			top: -2.5rem;
			left: -1rem;
			z-index: 20;
		}
	}
	.post-casino-3 {
		order: 4;
		.num {
			color: #f2994a;
		}
		&:before {
			content: "";
			width: 4.25rem;
			height: 4.25rem;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-image: url(../img/top-3.png);
			position: absolute;
			top: -2.5rem;
			left: -0.75rem;
			z-index: 20;
		}
	}
	.post-casino-4 {
		order: 1;
		.num {
			color: #01304b;
		}
	}
	.post-casino-5 {
		order: 5;
		.num {
			color: #01304b;
		}
	}

	@media (max-width: 992px) {
		.post-casino-1 {
			order: 1;
		}
		.post-casino-2 {
			order: 2;
		}
		.post-casino-3 {
			order: 3;
		}
		.post-casino-4 {
			order: 4;
		}
		.post-casino-5 {
			order: 5;
		}
	}
}
