/***** FONTS *****/
@import "fonts";
@import "variables";

@import "general";
// @import "blocks/title";

/***** BLOCKS GLOBAL *****/
@import "blocks/header";
@import "blocks/navigation";
@import "blocks/breadcrumbs";
@import "blocks/title";
@import "blocks/buttons";
@import "blocks/top";
@import "blocks/form";
@import "blocks/modal";
@import "blocks/sort";
@import "blocks/pagination";
@import "blocks/footer";
@import "blocks/carousel";
/***** COMPONENTS GLOBAL *****/
@import "blocks/post";
@import "blocks/post-casino";
@import "blocks/preview-casino";
@import "blocks/post-game";
@import "blocks/post-provider";
@import "blocks/post-offer";
@import "blocks/post-guide";
@import "blocks/article";
@import "blocks/comments";

/***** SECTIONS GLOBAL (Widgets) *****/

@import "widgets/widget-top-casinos";
@import "widgets/widget-img-bg"; //реклама в сайдбаре
@import "widgets/context-menu";
@import "widgets/slider-casino";
/***** Page GLOBAL *****/
@import "pages/content-page";
@import "pages/single";
@import "pages/casino";
@import "pages/game";
@import "pages/blog";
@import "pages/error";

.cn-close-icon {
	@media (max-width: 992px) {
		right: 10px;
		top: 15px;
	}
}
.cookie-notice-container,
.cookie-revoke-container {
	@media (max-width: 480px) {
		padding: 15px 35px;
	}
}
#cookie-notice .cn-button:not(.cn-button-custom) {
	padding: 0.25rem 2rem;
	min-height: 2rem;
	border-radius: 0.1875rem;
	background: var(--color_1);
	box-shadow: 0 0.625rem 1.875rem 0 rgba(0, 0, 0, 0.08);
	color: var(--color_0);
	line-height: 1.5;
	letter-spacing: 0.012px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 480px) {
		margin: 15px 0 0;
	}
}
