article {
	h1,
	h2,
	h3,
	h4 {
		font-family: "Inter-Semibold";
		color: var(--color_5);
		margin: 1.5rem 0;
		&:first-child {
			margin-top: 0;
		}
	}



	h1{
		font-size: 2.25rem;
		line-height: 1.2;
		@media (max-width: 680px) {
			font-size: 1.2rem;
		}
	}
	h2 {
		font-size: 2rem;
		line-height: 1.2;
		@media (max-width: 680px) {
			font-size: 1.6rem;
		}
	}
		h3 {
		font-size: 1.5rem;
		line-height: 1.2;

		@media (max-width: 680px) {
			font-size: 1.2rem;
		}
	}

	h1, h2{
		position: relative;
		padding-bottom: 0.75rem;
		&:after{
			content: '';
			display: block;
			width: 9.375rem;
			height: 1px;
			border-bottom: 3px dashed var(--color_3);
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	p {
		color: var(--color_7);
		font-size: pxToRem(16);
		font-weight: 400;
		line-height: 125%;
		margin: 1rem 0;
		&:first-child {
			margin-top: 0;
		}
		&.has-small-font-size {
			font-size: 0.875rem;
			margin: 1rem 0;
			color: var(--color_8);
		}
		&.has-large-font-size {
			font-size: 1.5rem !important;
			margin-bottom: 0.5rem;
			margin-top: -1.5rem;
		}
		a {
			color: var(--color_2);
			text-decoration: underline;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 1.5rem 0;
		width: 100%;

		li {
			padding-left: 1.25rem;
			margin: 0.5em 0;
			position: relative;
			&:before {
				content: "";
				width: 0.375rem;
				height: 0.375rem;
				background: linear-gradient(264deg, var(--color_2) 3%, var(--color_1) 95.71%);
				border-radius: 100%;
				position: absolute;
				left: 0;
				top: 0.5rem;
			}
		}
		&.class-1{
			li{
					&:before {
				content: "";
				width: 1rem;
				height: 1rem;
				background-image: url(../img/icons/fishka.svg);
				background-size: contain;
				border-radius: 0;
				position: absolute;
				left: 0;
				top: 0.5rem;
			}
			}
		}
	}
	ol {
		padding: 0 0 0 1.25rem;
		margin: 1.5rem 0;
		width: 100%;
		li {
			counter-increment: listCounter;
			padding-left: 0.3125rem;
			margin: 0.5em 0;
			position: relative;
			&::marker {
				color: var(--color_2);
				font-size: 1rem;
				margin-top: 0.3125rem;
			}
			ul{
				li{
					padding-left: 1.25rem;
				}
			}
		}
	}

	.wp-block-image {
		margin: 1.5rem 0;
		img {
			border-radius: 0.625rem;
		}
		&.size-full {
			img {
				width: 100%;
			}
		}
	}
}

.content-casino {
	article {
		padding-top: 0.5rem;
	}
}
.content-game {
	article {
		padding-top: 0.5rem;
	}
}
